import {AbstractEditModal} from '../abstract_edit_modal'
import {
    activeJobDataProvider,
    ActiveJobDataProvider,
    ActiveJobDto,
    IActiveJobDto,
    StoreDefaultActiveJobUserRequest
} from '../../../model/active_job'
import {IUserGroupDto, userGroupDataProvider} from '../../../model/usergroup'
import {
    confirmYesNo,
    convertDateToInputFormat,
    convertDateToTimeNumber,
    convertTimeNumberToTimeStr,
    convertTimeStrToTimeNumber,
    HashMap,
    ICallback,
    mustNotNull,
    toStringOrEmpty
} from '../../../tools/tools'
import {
    addOption,
    fillSelectWithTimes,
    isChecked,
    onChange,
    onClick,
    onKeyUp,
    setChecked2,
    setDisable
} from '../../../tools/templateTools'
import {IUserDto, Roles, userDataProvider} from '../../../model/user'
import {createNewFromTemplate} from '../../../tools/templates'
import {
    ActiveUserJobDto, IActiveUserJobDto
} from '../../../model/active_user_job'
import moment from 'moment'
import {calendarSettingsDataProvider} from '../../../model/calendar_settings'
import {convertDateToDayOfWeek, DayOfWeek} from '../../../model/dayofweek'
import {taskTypeDataProvider} from '../../../model/tasktype'
import {refreshUIAfterJobChanged} from './calendar_tools'
import {gt} from '../../../tools/translation'
import {Events} from '../../../tools/calendar_widget'
import {actionsInternal} from '../../page_manager'
import {TimeTools} from "../../../tools/time_tools";
import {IWorkerAwayDto} from "../../../model/worker_away";
import {mitarbeiterGruppenPage} from "../../list/mitarbeitergruppen_edit_page";
import {globalstate} from "../../../model/globalstate";

export class MaHinzufuegenModal extends AbstractEditModal<IActiveJobDto, ActiveJobDataProvider> {
    private static instance: MaHinzufuegenModal
    private userGroups: HashMap<IUserGroupDto> = new HashMap<IUserGroupDto>()
    private animationDurationInMs = 1000

    private TemplateId_SelectableMa = 'ma-hinzufuegen-selectable-ma-template'
    private TemplateId_MaRow = 'ma-hinzufuegen-ma-row'
    private TemplateId_DefaultMa = 'ma-hinzufuegen-ma-default-worker-template'

    private HtmlClass_AwayContainer = '.ma-hinzufuegen-ma-row-away'
    private HtmlClass_WayBackContainer = '.ma-hinzufuegen-ma-row-wayback'

    private workTimeFkts: HashMap<ICallback<void, number>> = new HashMap()
    private selectableMaRows: HashMap<JQuery> = new HashMap()
    private workTimeSum = 0
    private currentDayOfWeek: DayOfWeek = DayOfWeek.MONDAY
    private activeJobDtos: IActiveJobDto[] | any = []

    constructor() {
        super(activeJobDataProvider, 'ma-hinzufuegen')
        if (MaHinzufuegenModal.instance) {
            return MaHinzufuegenModal.instance
        }
        return this
    }

    protected getTemplateIds(): string[] {
        return [this.TemplateId_SelectableMa, this.TemplateId_MaRow,
            this.TemplateId_DefaultMa]
    }

    protected getHtmlDir(): string {
        return 'kalendar'
    }

    protected createNewDto(): IActiveJobDto {
        throw Error('Not supported!')
    }

    protected fillDefaults(callback: Function) {
        this.workTimeFkts.clear()
        this.selectableMaRows.clear()

        this.getMaRowsHtml().empty()

        super.fillDefaults(callback)
    }

    public setDefaultStart(date: Date) {
        this.getStartCalendarHtml()
            .dispatchEvent(new CustomEvent(Events.DATE_SELECTED, {
                detail: {date}
            }))
    }

    protected postHtmlLoaded() {
        onChange($('#ma-hinzufuegen-ma-row-away-checkbox'),
            () => this.handleWayCheckboxChanged(this.HtmlClass_AwayContainer,
                'ma-hinzufuegen-ma-row-away-checkbox'))
        onChange($('#ma-hinzufuegen-ma-row-wayback-checkbox'),
            () => this.handleWayCheckboxChanged(this.HtmlClass_WayBackContainer,
                'ma-hinzufuegen-ma-row-wayback-checkbox'))
        onChange(this.getUserGroupsHtml(), this.handleUserGroupChanged)

        onClick(this.getSaveBtnHtml(), () => this.saveToServer())

        onClick(this.getDefaultSaveBtnHtml(), this.saveDefaults)

        onClick(this.getDefaultEditPencilHtml(), () => {
            if (!confirmYesNo(
                "Änderungen gehen verlohren, trotzdem zu den Mitarbeitergruppen wechseln?")) {
                return
            }
            this.hideDialog()
            if (this.dto?.job!.building!.userGroupId) {
                actionsInternal.loadMitarbeiterGruppen(
                    () => mitarbeiterGruppenPage.setCurrentUserGroupById(
                        this.dto?.job!.building!.userGroupId!));
            }
        })

        onClick(this.getCloseBtnHtml(), () => {
            this.hideDialog()
        })

        onKeyUp(this.getMaSearchFieldHtml(), this.filterMa)
    }

    protected postCloseDialog() {
        if (this.hasChanged) {
            refreshUIAfterJobChanged(true);
        }
    }

    protected fillFromDto(dto: IActiveJobDto) {
        let currentDto = this.getDtoOrCreate()
        if (!currentDto.date || !dto.job) {
            throw new Error()
        }
        this.currentDayOfWeek = convertDateToDayOfWeek(
            new Date(currentDto.date))

        //fill ma rows
        activeJobDataProvider.loadListAtDate(new Date(currentDto.date),
            activeJobs => {
                this.activeJobDtos = activeJobs.activeJobDtos
                dto.activeUserJobs?.forEach(ma => {
                    userDataProvider.loadOrGetFromCacheInternalManagedCache(
                        ma.userId,
                        user => this.addMaRowFromActiveUserJob(user, ma))
                });
                this.calculateWorkTime()

                //load user
                this.handleUserGroupChanged()
            })

        //away / way back
        setChecked2(this.getAwayCheckboxHtml(), !dto.withAway)
        setChecked2(this.getWayBackCheckboxHtml(), !dto.withWayBack)

        //worktime
        this.getWorkTimeExpectedHtml().text(dto.workHours + '')
        this.fillDefaultWorker(dto)

        //building
        this.getBuildingCustomerIdHtml().text(
            dto.job.building.customId ? `${dto.job.building.customId}` : '')
        let dayOfWeek = convertDateToDayOfWeek(currentDto.date)
        this.getBuildingNameHtml().text(dto.job.building.name + ' (' + gt(
            dayOfWeek + '-short') + ' ' + moment(currentDto.date)
            .format('DD.MM.YY') + ')')
        this.setDefaultStart(currentDto.date)
        //load user groups
        this.getUserGroupsHtml().empty()
        addOption(this.getUserGroupsHtml(), -1,
            gt('ma-hinzufuegen.left.dropdown.all'))
        this.userGroups.clear()
        this.handleUserGroups(
            mustNotNull(userGroupDataProvider.loadListAllManagedCache?.content),
            dto);
    }

    private handleUserGroups(userGroups: IUserGroupDto[], dto: IActiveJobDto) {

        userGroups.forEach(userGroup => {
            if (dto.job?.building.userGroupId) {
                if (userGroup.buildingId && dto.job?.building.userGroupId !== userGroup.id) {
                    return
                }
            } else if (userGroup.buildingId) {
                return
            }
            addOption(this.getUserGroupsHtml(), userGroup.id,
                userGroupDataProvider.getFullName(userGroup))
            this.userGroups.put(userGroup.id, userGroup)
        })
    }

    protected cleanDataBeforeSave(dto: IActiveJobDto): IActiveJobDto {
        //Werte die sich ändern sollen, alles andere null
        let newDto = new ActiveJobDto()
        newDto.id = dto.id
        newDto.activeUserJobs = dto.activeUserJobs
        newDto.withAway = dto.withAway
        newDto.withWayBack = dto.withWayBack
        newDto.defaultActiveUserJobs = dto.defaultActiveUserJobs
        newDto.workWindowStart = dto.workWindowStart
        newDto.workWindowEnd = dto.workWindowEnd
        return newDto
    }

    private getMaSearchFieldHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-search-field')
    }

    private getBuildingCustomerIdHtml(): JQuery {
        return $('#ma-hinzufuegen-building-customer-id')
    }

    private getBuildingNameHtml(): JQuery {
        return $('#ma-hinzufuegen-building-name')
    }

    private saveDefaults() {
        this.hasChanged = true
        let dto = this.getDtoOrCreate()
        if (!dto.job || !dto.activeUserJobs || !dto.job.id) {
            throw new Error()
        }

        const _startVal = `${this.getDefaultStartHtml().val()}`
        const _endVal = `${this.getDefaultEndHtml().val()}`
        const startDate = _startVal.length ?
            _startVal.split('.').reverse().join('/') : null
        const endDate = _endVal.length > 0 ?
            _endVal.split('.').reverse().join('/') : null
        if (!startDate) {
            return
        }

        dto.defaultActiveUserJobs = dto.activeUserJobs?.concat()

        let request = new StoreDefaultActiveJobUserRequest(dto.job.id,
            new Date(`${startDate}`), endDate ? new Date(`${endDate}`) : null,
            dto.defaultActiveUserJobs)

        activeJobDataProvider.saveDefaults(request, () => {
            globalstate.clearCache();
            /*
            this.fillDefaultWorker(dto)

            const a = document.querySelectorAll(
                '.ma-hinzufuegen-ma-row-is-default');
            a.forEach(b => b.remove());
            const target = document.querySelectorAll(
                '.ma-hinzufuegen-ma-row-name');
            target.forEach((e) => {
                const elem = document.createElement('span');
                elem.classList.add('ma-hinzufuegen-ma-row-is-default');
                elem.innerText = '*';

                e.parentNode?.insertBefore(elem, e);
            })
             */
            this.hideDialog();
        })

    }

    private fillDefaultWorker(dto: IActiveJobDto) {
        this.getDefaultContainerHtml().show()
        this.getDefaultWorkerContainerHtml().show()

        this.getDefaultWorkerListHtml().empty()
        if (dto.job?.building!.userGroupId) {
            let objectUserGroup = userGroupDataProvider.getFromCache(
                dto.job?.building!.userGroupId,
                userGroupDataProvider.loadListAllManagedCache);

            if (objectUserGroup?.memberIds) {
                objectUserGroup?.memberIds!.forEach(userId => {
                    userDataProvider.loadOrGetFromCacheInternalManagedCache(
                        userId, user => {
                            let template = createNewFromTemplate(
                                this.TemplateId_DefaultMa)
                            template.find(
                                '.ma-hinzufuegen-ma-default-worker-name')
                                .text(userDataProvider.getShortRealName(user))
                            this.getDefaultWorkerListHtml().append(template)
                        })
                })
            }
        }
    }

    protected collectDataToDto(dto: IActiveJobDto) {
        //away / way back
        dto.withAway = isChecked(this.getAwayCheckboxHtml())
        dto.withWayBack = isChecked(this.getWayBackCheckboxHtml())

        //auf 0 setzen, wenn disabled
        dto.activeUserJobs?.forEach(ma => {
            if (!dto.withAway) {
                ma.awayHours = 0
            }
            if (!dto.withWayBack) {
                ma.wayBackHours = 0
            }
        })
    }

    private getSaveBtnHtml(): JQuery {
        return $('#ma-hinzufuegen-save-btn')
    }

    private getCloseBtnHtml(): JQuery {
        return $('#ma-hinzufuegen-close-btn')
    }

    private handleWayCheckboxChanged(targetContainerClass: string,
                                     targetCheckboxId: string) {
        let containers = $(targetContainerClass)
        let enabled = isChecked($('#' + targetCheckboxId))
        for (let i = 0; i < containers.length; i++) {
            if (enabled) {
                $(containers[i]).show(this.animationDurationInMs)
            } else {
                $(containers[i]).hide(this.animationDurationInMs)
            }
        }
    }

    private addMaRowFromUser(user: IUserDto) {
        if (!user.id) {
            throw new Error('User not set!')
        }
        if (activeJobDataProvider.existUserInUserList(user,
            this.getDtoOrCreate())) {
            return
        }
        let newActiveUserJob = new ActiveUserJobDto()
        newActiveUserJob.userId = user.id
        if (!this.getDtoOrCreate().activeUserJobs) {
            this.getDtoOrCreate().activeUserJobs = []
        }
        this.getDtoOrCreate().activeUserJobs?.push(newActiveUserJob)

        this.addMaRowFromActiveUserJob(user, newActiveUserJob)
    }

    private calculateWorkTimeForRow(workTimeStart: JQuery, workTimeEnd: JQuery,
                                    workTime: JQuery): number {
        let start = +(workTimeStart.val() + '')
        let end = +(workTimeEnd.val() + '')
        let diff = end - start
        workTime.text(diff + '')
        return diff
    }

    private calculateWorkTime() {
        this.workTimeSum = 0
        this.workTimeFkts.forEach(workTimeFKt => {
            this.workTimeSum += workTimeFKt()
        })
        this.getWorkTimeSumHtml().text(this.workTimeSum + '')
        const worktimeExpected = `${this.getWorkTimeExpectedHtml().text()}`
        const worktimeSum = `${this.getWorkTimeSumHtml().text()}`

        const sameWorktime = Number(worktimeExpected) === Number(worktimeSum)
        if (sameWorktime) {
            document.getElementById('ma-hinzufuegen-ma-row-worktime-sum')
                ?.classList.add('sameWorktime')
        } else {
            document.getElementById('ma-hinzufuegen-ma-row-worktime-sum')
                ?.classList.remove('sameWorktime')
        }
    }

    private getWorkTimeExpectedHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-row-worktime-expected')
    }

    private getWorkTimeSumHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-row-worktime-sum')
    }

    private filterMa() {
        let filter = toStringOrEmpty(this.getMaSearchFieldHtml().val()).trim()
            .toLowerCase()

        this.selectableMaRows.forEach(row => {
            if (filter.length === 0) {
                row.show()
            } else {
                let name = row.find('.ma-hinzufuegen-selectable-ma-name')
                    .text() + ''
                if (name.toLowerCase().includes(filter)) {
                    row.show()
                } else {
                    row.hide()
                }
            }
        })
    }

    private async addMaRowFromActiveUserJob(user: IUserDto,
                                            activeUserJob: IActiveUserJobDto) {
        //possible worktime
        let possibleWorkTime = user.possibleWorkTimes?.find(
            possibleWorkTime => possibleWorkTime.dayOfWeek === this.currentDayOfWeek);


        const workWindowStart = convertTimeStrToTimeNumber(
            this.getDtoOrCreate().workWindowStart);
        const workWindowEnd = convertTimeStrToTimeNumber(
            this.getDtoOrCreate().workWindowEnd, true);
        let activeUserJobStartTime = activeUserJob.startTime ?
            convertDateToTimeNumber(activeUserJob.startTime) : workWindowStart;
        let possibleWorkTimeStartTime = activeUserJobStartTime;
        let activeUserJobEndTime = activeUserJob.endTime ?
            convertDateToTimeNumber(activeUserJob.endTime, true) :
            workWindowEnd;
        let possibleWorkTimeEndTime = activeUserJobEndTime;
        let notWorkingToDay = true;
        if (possibleWorkTime) {
            notWorkingToDay = false;
            possibleWorkTimeStartTime = convertTimeStrToTimeNumber(
                possibleWorkTime.startTime);
            possibleWorkTimeEndTime = convertTimeStrToTimeNumber(
                possibleWorkTime.endTime, true);
        }

        let timeForSelectForStartTime = Math.min(workWindowStart,
            activeUserJobStartTime);
        let timeForSelectForEndTime = Math.max(workWindowEnd,
            activeUserJobEndTime);
        let row = createNewFromTemplate(this.TemplateId_MaRow)
        this.getMaRowsHtml().append(row)
        row.find('.ma-hinzufuegen-ma-row-name')
            .text(userDataProvider.getShortRealName(user))

        //default MA
        if (!activeJobDataProvider.existUserInUserDefaultList(user,
            this.getDtoOrCreate())) {
            row.find('.ma-hinzufuegen-ma-row-is-default').remove()
        }

        //workTime
        let workTime = row.find('.ma-hinzufuegen-ma-row-worktime')
        let workTimeStart = row.find('.ma-hinzufuegen-ma-row-start-worktime')
        let workTimeEnd = row.find('.ma-hinzufuegen-ma-row-end-worktime')
        let workTimeFkt = () => {
            return this.calculateWorkTimeForRow(workTimeStart, workTimeEnd,
                workTime)
        }
        this.workTimeFkts.put(user.id, workTimeFkt)

        const labelFktForTimeSelection = (isEndTime: boolean) => (time: number) => {
            let result: string[] = [];
            if (notWorkingToDay) {
                result.push("keine Arbeitszeit");
            } else {
                if (possibleWorkTimeStartTime > time) {
                    //before workers possible worktime
                    result.push("keine Arbeitszeit");
                }
                if (possibleWorkTimeEndTime < time || !isEndTime && (possibleWorkTimeEndTime === time)) {
                    //after workers possible worktime
                    result.push("keine Arbeitszeit");
                }
            }
            if (workWindowStart > time) {
                //before possible worktime
                result.push("außerhalb des Arbeitszeitfensters");
            }
            if (workWindowEnd < time) {
                //after possible worktime
                result.push("außerhalb des Arbeitszeitfensters");
            }
            let maIsNotAway = this.getMaIsNotAway(user, time, true);
            let maIsNotInAJob = this.getMaIsNotInAJob(user, time, true);
            if (!maIsNotAway) {
                result.push("abwesend"); //TODO translate
            }
            if (!maIsNotInAJob) {
                result.push("verplant"); //TODO translate
            }
            return result.length > 0 ?
                convertTimeNumberToTimeStr(time) + " (" + result.join(
                    ", ") + ")" : "";
        };

        //work time start
        calendarSettingsDataProvider.fillSelectWithTimes(workTimeStart,
            timeForSelectForStartTime,
            timeForSelectForEndTime - calendarSettingsDataProvider.current!.content!.stepsInH!,
            time => {
                return true;
            }, labelFktForTimeSelection(false));
        workTimeStart.val(activeUserJob.startTime ?
            convertDateToTimeNumber(activeUserJob.startTime) : "");
        onChange(workTimeStart, () => {
            activeUserJob.startTime = new Date(convertDateToInputFormat(
                this.getDtoOrCreate().date) + ' ' + convertTimeNumberToTimeStr(
                workTimeStart.val()))
            setDisable(workTimeEnd, activeUserJob.startTime === null);

            //fill possible endtimes
            let validEndTime = false;
            let allFallowNotPossible = false;
            const startTimeNumber = TimeTools.getTimeNumberFromDate(
                activeUserJob.startTime!);
            calendarSettingsDataProvider.fillSelectWithTimes(workTimeEnd,
                convertTimeStrToTimeNumber(
                    this.getDtoOrCreate().workWindowStart),
                convertTimeStrToTimeNumber(this.getDtoOrCreate().workWindowEnd,
                    true), time => {
                    if (time <= startTimeNumber) {
                        return false;
                    }
                    if (allFallowNotPossible) {
                        return false;
                    }
                    if (timeForSelectForStartTime > time) {
                        //before workers possible worktime
                        return false;
                    }
                    if (timeForSelectForEndTime < time) {
                        //after workers possible worktime
                        return false;
                    }
                    if (!validEndTime && activeUserJob.endTime) {
                        validEndTime = TimeTools.getTimeNumberFromDate(
                            activeUserJob.endTime, true) === time;
                    }
                    let maIsNotAway = this.getMaIsNotAway(user, time, false);
                    let maIsNotInAJob = this.getMaIsNotInAJob(user, time,
                        false);
                    let possible = maIsNotAway && maIsNotInAJob;
                    allFallowNotPossible = !possible;
                    return possible;
                }, labelFktForTimeSelection(true));
            if (validEndTime && activeUserJob.endTime) {
                workTimeEnd.val(
                    convertDateToTimeNumber(activeUserJob.endTime, true))
            } else {
                workTimeEnd.val("");
            }
            this.calculateWorkTime();
        })

        //work time End
        calendarSettingsDataProvider.fillSelectWithTimes(workTimeEnd,
            convertTimeStrToTimeNumber(this.getDtoOrCreate().workWindowStart),
            convertTimeStrToTimeNumber(this.getDtoOrCreate().workWindowEnd,
                true))
        workTimeEnd.val(activeUserJob.endTime ?
            convertDateToTimeNumber(activeUserJob.endTime, true) : "");
        setDisable(workTimeEnd, activeUserJob.startTime === null);
        onChange(workTimeEnd, () => {
            let dateTimeStr = convertDateToInputFormat(
                this.getDtoOrCreate().date) + ' ' + convertTimeNumberToTimeStr(
                workTimeEnd.val())
            activeUserJob.endTime = new Date(dateTimeStr)
            this.calculateWorkTime()
        })

        //away
        if (!isChecked(this.getAwayCheckboxHtml())) {
            row.find(this.HtmlClass_AwayContainer).hide()
        }
        let awaySelect = row.find('.ma-hinzufuegen-ma-row-away')
        fillSelectWithTimes(awaySelect, 0, 2, 0.25)
        awaySelect.val(activeUserJob.awayHours)
        onChange(awaySelect, () => {
            activeUserJob.awayHours = +(awaySelect.val() + '')
        })

        //way back
        if (!isChecked(this.getWayBackCheckboxHtml())) {
            row.find(this.HtmlClass_WayBackContainer).hide()
        }
        let wayBackSelect = row.find('.ma-hinzufuegen-ma-row-wayback')
        fillSelectWithTimes(wayBackSelect, 0, 2, 0.25)
        wayBackSelect.val(activeUserJob.wayBackHours)
        onChange(wayBackSelect, () => {
            activeUserJob.wayBackHours = +(wayBackSelect.val() + '')
        })

        //remove btn
        let removeBtn = row.find('.ma-hinzufuegen-ma-row-remove-btn')
        removeBtn.addClass('clickable')
        onClick(removeBtn, () => {
            if (!user.id) {
                throw Error('Error not set!')
            }
            this.workTimeFkts.remove(user.id)
            row.remove()
            activeJobDataProvider.removeUser(user, this.getDtoOrCreate())
            this.addUserSelectable(user, this.activeJobDtos)
            this.calculateWorkTime()
        })
        this.getMaRowsHtml().append(row)

        //worktime
        this.calculateWorkTime()
    }

    private getMaIsNotInAJob(user: IUserDto, time: number,
                             startTime: boolean): boolean {
        let jobsOfTheWorkerOnThisDay = userDataProvider.getDayJobs(user,
            this.activeJobDtos)
        const maIsInAJob = jobsOfTheWorkerOnThisDay.find(userDayJob => {
            if (this.dto!.id === userDayJob.activeJob.id) {
                return false;
            }
            let startTimeNumber = TimeTools.getTimeNumberFromDate(
                userDayJob.activeUserJob.startTime!);
            if (userDayJob.activeUserJob.awayHours > 0) {
                startTimeNumber -= userDayJob.activeUserJob.awayHours;
            }
            let endTimeNumber = TimeTools.getTimeNumberFromDate(
                userDayJob.activeUserJob.endTime!, true);
            if (userDayJob.activeUserJob.wayBackHours > 0) {
                endTimeNumber += userDayJob.activeUserJob.wayBackHours;
            }
            if (startTime) {
                return (startTimeNumber <= time && time < endTimeNumber);
            } else {
                return (startTimeNumber < time && time <= endTimeNumber);
            }
        });
        return maIsInAJob === undefined;
    }

    private getMaIsNotAway(user: IUserDto, time: number,
                           startTime: boolean): boolean {
        return this.handleAways(user, true, away => {
            const startTimeNumber = TimeTools.getTimeNumberFromDate(
                away.startDate!);
            const endTimeNumber = TimeTools.getTimeNumberFromDate(away.endDate!,
                true);
            if (startTime) {
                return !(startTimeNumber <= time && time < endTimeNumber);
            } else {
                return !(startTimeNumber < time && time <= endTimeNumber);
            }
        });
    }

    private getAwayCheckboxHtml() {
        return $('#ma-hinzufuegen-ma-row-away-checkbox')
    }

    private getWayBackCheckboxHtml() {
        return $('#ma-hinzufuegen-ma-row-wayback-checkbox')
    }

    private getMaRowsHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-rows')
    }

    private getWorkWindowStartHtml(): JQuery {
        return $('#ma-hinzufuegen-workwindow-start')
    }

    private getWorkWindowEndHtml(): JQuery {
        return $('#ma-hinzufuegen-workwindow-end')
    }

    private handleUserGroupChanged() {
        this.getUserSelectableHtml().empty()
        this.selectableMaRows.clear()

        let userGroupId = +(this.getUserGroupsHtml().val() + '')

        if (!userGroupId || userGroupId === -1) {
            this.handleAllWorkers(mustNotNull(
                userDataProvider.loadListAllInternalManagedCache?.content));
        } else {
            userGroupDataProvider.loadOrGetFromCacheAll(userGroupId,
                (userGroup) => {
                    userGroup.memberIds?.forEach(
                        memberId => this.addUserSelectable(mustNotNull(
                            userDataProvider.getFromAllInternalManagedCache(
                                memberId)), this.activeJobDtos))
                    this.filterMa()
                })
        }
    }

    private handleAllWorkers(members: IUserDto[]) {
        members.forEach(
            member => this.addUserSelectable(member, this.activeJobDtos))
        this.filterMa()
    }

    private addUserSelectable(user: IUserDto, activeJobDtos: IActiveJobDto[]) {
        if (!user.active) {
            return
        }

        if (!user.roles?.includes(Roles.WORKER)) {
            return
        }

        if (activeJobDataProvider.existUserInUserList(user,
            this.getDtoOrCreate())) {
            return
        }

        const startWorkWindow = convertTimeStrToTimeNumber(
            this.getDtoOrCreate().workWindowStart);
        const endWorkWindow = convertTimeStrToTimeNumber(
            this.getDtoOrCreate().workWindowEnd, true);

        //possible worktime
        let possibleWorkTime = user.possibleWorkTimes?.find(
            possibleWorkTime => possibleWorkTime.dayOfWeek === this.currentDayOfWeek);
        if (!possibleWorkTime) {
            //worker works not on this day
            return;
        }
        const startPossibleWorkWindow = convertTimeStrToTimeNumber(
            possibleWorkTime.startTime);
        if (startPossibleWorkWindow >= endWorkWindow) {
            //worker works after workWindowStart
            return;
        }
        const endPossibleWorkWindow = convertTimeStrToTimeNumber(
            possibleWorkTime.endTime, true);
        if (endPossibleWorkWindow <= startWorkWindow) {
            //worker works before workwindow
            return;
        }

        //other jobs
        let foundOneFreeTime = false
        for (let time = startWorkWindow; time < endWorkWindow; time += calendarSettingsDataProvider.current!.content!.stepsInH!) {
            if (this.getMaIsNotInAJob(user, time, true)) {
                foundOneFreeTime = true;
                break;
            }
        }
        if (!foundOneFreeTime) {
            //worker has no free time slote, ma works already in other jobs
            return;
        }

        // worker away
        const ifUserAway = this.handleAways(user, false, away => {
            if (away.fullDay) {
                return true;
            } else {
                let start = TimeTools.setTime(this.dto?.date!,
                    this.dto?.workWindowStart!)
                let end = TimeTools.setTime(this.dto?.date!,
                    this.dto?.workWindowEnd!)
                //the away cover the hole work windows
                if (moment(away.startDate).isSameOrBefore(start)) {
                    if (moment(away.endDate).isSameOrAfter(end)) {
                        return true;
                    }
                }
            }
            return false;
        })
        if (ifUserAway) {
            return;
        }

        //task type match
        let jobTasks = this.getDtoOrCreate().tasks
        if (jobTasks && jobTasks.length > 0) {
            if (user.taskTypes && user.taskTypes.length > 0) {
                if (!taskTypeDataProvider.aInsideB(jobTasks, user.taskTypes)) {
                    return
                }
            }
        }

        let possibleWorkTimeOfTheWorker = userDataProvider.getPossibleWorkTime(
            user, this.currentDayOfWeek)
        let jobsOfTheWorkerOnThisDay = userDataProvider.getDayJobs(user,
            activeJobDtos)
        let workTimeOfTheWorkerOnThisDay = userDataProvider.getWorkTime(0, 24,
            possibleWorkTimeOfTheWorker, user, this.currentDayOfWeek,
            calendarSettingsDataProvider.current.content,
            jobsOfTheWorkerOnThisDay)

        let selectbaleMa = createNewFromTemplate(this.TemplateId_SelectableMa)
        selectbaleMa.find('.ma-hinzufuegen-selectable-ma-free-time')
            .text(workTimeOfTheWorkerOnThisDay.freeTime + '')

        let maNameHtml = selectbaleMa.find('.ma-hinzufuegen-selectable-ma-name')
        maNameHtml.text(userDataProvider.getShortRealName(user))
        onClick(selectbaleMa, () => {
            this.selectableMaRows.remove(user.id + '')
            selectbaleMa.remove()
            this.addMaRowFromUser(user)
        })

        if (possibleWorkTimeOfTheWorker.sumTime <= 0) {
            maNameHtml.addClass('ma-hinzufuegen-selectable-ma-not-working')
            selectbaleMa.attr('title', 'Arbeitet nicht an diesem Tag!')
        }

        this.getUserSelectableHtml().append(selectbaleMa)

        const countUsers = document.getElementById(
            'ma-hinzufuegen-selectable-users')?.childNodes.length;

        this.getUserCounteHtml()
            .text('Mit Auto' + ' ' + `(${String(countUsers)} Personen)`)

        this.selectableMaRows.put(user.id, selectbaleMa)
    }

    private handleAways<RESULT>(user: IUserDto, defaultResult: RESULT,
                                handler: ICallback<IWorkerAwayDto, RESULT>): RESULT {
        if (user.aways) {
            for (const away of user.aways) {
                if (TimeTools.isDateBetween(away.startDate!, away.endDate!,
                    this.dto?.date!)) {
                    return handler(away);
                }
            }
        }
        return defaultResult;
    }

    private getUserCounteHtml(): JQuery {
        return $('#ma-hinzufuegen-mit-auto')
    }

    private getUserSelectableHtml(): JQuery {
        return $('#ma-hinzufuegen-selectable-users')
    }

    private getUserGroupsHtml(): JQuery {
        return $('#ma-hinzufuegen-usergroups-select')
    }

    private getDefaultStartHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-start')
    }

    private getStartCalendarHtml() {
        return document.querySelector(
            '#ma-hinzufuegen-calendar-start') as HTMLElement
    }

    private getDefaultEndHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-end')
    }

    private getDefaultContainerHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-container')
    }

    private getDefaultWorkerContainerHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-worker-container')
    }

    private getDefaultEditPencilHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-edit-pencil')
    }

    private getDefaultWorkerListHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-worker-list')
    }

    private getDefaultSaveBtnHtml(): JQuery {
        return $('#ma-hinzufuegen-ma-default-save-btn')
    }
}

export const maHinzufuegenModal = new MaHinzufuegenModal()
