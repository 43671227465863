import moment from 'moment'
import {Tooltip} from './tooltip'
import {gt} from './translation'
import {debounce} from './tools'

const DayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December']

export const Events = {
    DATE_SELECTED: 'dateSelected',
    DATE_UPDATED: 'dateUpdated',
    CALENDAR_OPEN: 'calendarOpen',
    CLOSE: 'closeCalendar',
    SET_TODAY: 'setToday'
}

const ClassNames = {
    BTN: 'calendar__btn',
    BTN_DISABLED: 'calendar__btn_disabled',
    BTN_PREV: 'calendar__btn_prev',
    BTN_NEXT: 'calendar__btn_next',
    CONTAINER: 'calendar-container',
    BODY: 'calendar__body',
    TOOLTIP: 'calendar__tooltip',
    CONTROL: 'calendar__control',
    LABEL: 'calendar__label',
    DISPLAY: 'calendar__display',
    INPUT_FIELD: 'calendar__input',
    TODAY_BTN: 'calendar__today-btn',
    CLOSE_BTN: 'calendar__close-btn',
    HEADER: 'calendar__header',
    MONTH: 'calendar__month',
    ROOT: 'calendar',
    TABLE: 'calendar__table',
    TABLE_BODY: 'calendar__table-body',
    TABLE_CELL: 'calendar__table-cell',
    TABLE_COL_HEADER: 'calendar__table-col-header',
    TABLE_HEAD: 'calendar__table-head',
    TABLE_ROW: 'calendar__table-row',
    YEAR: 'calendar__year'
}

const Defaults = {
    separator: '.', space: ' '
}

interface HolidayAPIResponse {
    [region: string]: {
        [feast: string]: {
            datum: string
            hinweis: string
        }
    }
}

interface HolidayItem {
    region: string
    holidays: Array<{
        title: string
        datum: string
    }>
}

interface HolidayInfo {
    title: string
    regions: string
}

const getWeekNumber = (date: Date) => {
    return  moment(date).isoWeek();
}

class HolidayAPI {
    private static instance: HolidayAPI
    private _baseURL: string = `https://feiertage-api.de/api`
    private _holidays: Array<HolidayItem>
    private _list: Map<number, Array<HolidayItem>>

    private constructor() {
        const _year = new Date().getFullYear()
        const _range = 8

        this._list = new Map()
        this._holidays = []
        this._requestHolidaysByYear(_year)
        setTimeout(() => {
            this._saveYearHolidays(new Array(_range).fill(_year - (_range - 2))
                .map((_, i) => _ + i))
        }, 150)
    }

    public static getInstance(): HolidayAPI {
        if (!HolidayAPI.instance) {
            HolidayAPI.instance = new HolidayAPI()
        }

        return HolidayAPI.instance
    }

    public getHolidaysList() {
        return this._holidays
    }

    private _request(year: number) {
        return fetch(`${this._baseURL}/?jahr=${year}`)
    }

    private async _saveYearHolidays(years: Array<number>) {
        if (!years.length) return

        await Promise.all(years.map(async year => {
            const _req = await this._request(year)
            const _holidays: HolidayAPIResponse = await _req.json()
            this._list.set(year, this._transformToObject(_holidays))
        }))
    }

    private _requestHolidaysByYear(year: number) {
        try {
            this._request(year)
                .then(res => res.json())
                .then((data: HolidayAPIResponse) => {
                    this._holidays = this._transformToObject(data)
                    this._list.set(year, this._holidays)
                })
        } catch (err) {
            console.error(err)
        }
    }

    public getHolidaysByYear(year: number) {
        const _h = this._list.get(year)
        if (_h) {
            this._holidays = _h
        } else {
            this._requestHolidaysByYear(year)
        }
    }

    private _transformToObject(holidays: HolidayAPIResponse): Array<HolidayItem> {
        return Object.keys(holidays).map(region => {
            return {
                region: region,
                holidays: Object.entries(holidays[region]).map(entry => {
                    return {
                        title: entry[0], datum: entry[1].datum
                    }
                })
            }
        })
    }

    private _searchDate(date: Date) {
        const _year = date.getFullYear()
        const _month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` :
            `${date.getMonth()}`
        const _day = date.getDate() < 10 ? `0${date.getDate()}` :
            `${date.getDate()}`
        const _searchKey = `${_year}-${_month}-${_day}`

        return this._holidays.map(area => {
            return {
                region: area.region,
                holidays: area.holidays.filter(
                    _list => _list.datum.includes(_searchKey))
            }
        })
    }

    public checkDate(date: Date) {
        const _search = this._searchDate(date)
        const _result = {
            isHoliday: _search.some(item => item.holidays.length > 0),
            isNational: _search.every(item => item.holidays.length > 0)
        }
        return _result
    }

    public getInfo(date: Date): HolidayInfo {
        const _search = this._searchDate(date)
        const _filtered = _search.filter(item => item.holidays.length > 0)
        return {
            title: _filtered.map(item => item.holidays[0].title)[0],
            regions: _filtered.map(item => item.region).join(',')
        }
    }
}

const DEBOUNCE_TIME = 350

export class Calendar {
    private static API = HolidayAPI.getInstance()
    private static list: Map<number, Calendar>
    public id: number
    private _minDate: Date
    private _maxDate: Date
    private _weekMode: boolean

    private _root: HTMLElement
    private _container: HTMLElement
    private _today: Date
    private _selectedDate: Date
    private _month: number
    private _year: number

    private _dayNames: Array<string> = []
    private _monthNames: Array<string> = []
    private _startOfWeek: number = 1

    private _displayField: HTMLInputElement
    private _inputField: HTMLInputElement
    private _todayBtn: HTMLElement
    private _closeBtn: HTMLElement
    private _tableBody: HTMLElement
    private _monthElem: HTMLElement
    private _yearElem: HTMLElement
    private _prevBtn: HTMLElement
    private _nextBtn: HTMLElement

    constructor(root: HTMLElement,
                {today, selectedDate}: { today: Date; selectedDate?: Date }) {
        this.id = Math.floor(Math.random() * 100000)

        if (!Calendar.list) {
            Calendar.list = new Map()
        }

        Calendar.list.set(this.id, this)

        this._minDate = new Date(today.getFullYear() - 10, today.getMonth(),
            today.getDate())
        this._maxDate = new Date(today.getFullYear() + 2, today.getMonth(),
            today.getDate())
        this._today = today
        this._weekMode = root.dataset.mode ?
            root.dataset.mode.includes('week') : false
        this._root = root
        this._container = document.createElement('div')
        this._displayField = (root.querySelector(
            `input`) as HTMLInputElement) ?? (root.querySelector(
            `.${ClassNames.DISPLAY}`) as HTMLInputElement)
        this._selectedDate = selectedDate || new Date()
        root.setAttribute('data-id', `${this.id}`)

        if (!this._displayField) {
            const label = document.createElement('label')
            label.classList.add(ClassNames.LABEL)
            this._displayField = document.createElement('input')
            this._displayField.className = ClassNames.DISPLAY
            label.appendChild(this._displayField)
            this._root.appendChild(label)
        }

        this._month = this._selectedDate.getMonth()
        this._year = this._selectedDate.getFullYear()

        this._inputField = document.createElement('input')
        this._todayBtn = document.createElement('button')
        this._closeBtn = document.createElement('button')
        this._tableBody = document.createElement('tbody')
        this._monthElem = document.createElement('span')
        this._yearElem = document.createElement('span')
        this._prevBtn = document.createElement('button')
        this._nextBtn = document.createElement('button')

        if (this._weekMode) {
            this._initWeekMode()
        } else {
            this._initDayMode()
        }

        this._localize()
        this._createUi()
        this.setDate(this._selectedDate)
    }

    private _switchWeek(direction: number) {
        //1000ms = 1s * 60 = 1m * 60 = 1h * 24 = 24h * 7 = 7d = 1w
        const _current = this._selectedDate.getTime()
        const _week = 1000 * 60 * 60 * 24 * 7
        const _sign = direction > 0 ? 1 : -1
        const _newDate = _current + _week * _sign

        this.setDate(new Date(_newDate))
    }

    private _initWeekMode() {
        this._displayField.setAttribute('readonly', 'true')

        const prevWeek = this._root.querySelector(
            '[data-action="prev"]') as HTMLElement
        const nextWeek = this._root.querySelector(
            '[data-action="next"]') as HTMLElement

        prevWeek.removeEventListener('click', debounce(() => {
            this._switchWeek(-1)
        }, DEBOUNCE_TIME))

        prevWeek.addEventListener('click', debounce(() => {
            this._switchWeek(-1)
        }, DEBOUNCE_TIME))

        nextWeek.removeEventListener('click', debounce(() => {
            this._switchWeek(1)
        }, DEBOUNCE_TIME))

        nextWeek.addEventListener('click', debounce(() => {
            this._switchWeek(1)
        }, DEBOUNCE_TIME))
    }

    private _initDayMode() {
        this._displayField.setAttribute('readonly', 'true')
        this._inputField.type = 'text'
        this._inputField.pattern = `([0-9]{1,2})[${Defaults.separator}]([0-9]{1,2})[${Defaults.separator}]([0-9]{4})`
    }

    private _getDateForWeekInput() {
        const _week = this._getWeekNumber(this._selectedDate)
        const _prefix = gt('WEEK') ?? 'Woche'
        return {
            input: `${_week}`, display: `${_prefix} ${_week}, ${this._year}`
        }
    }

    private _getDateForDayInput() {
        const _d = this._selectedDate.getDate()
        const _day = _d < 10 ? `0${_d}` : _d
        const _month = `${this._month < 9 ? '0' : ''}${this._month + 1}`
        return {
            input: `${_day}${Defaults.separator}${_month}${Defaults.separator}${this._year}`,
            display: `${_day}${Defaults.separator}${_month}${Defaults.separator}${this._year}`
        }
    }

    private _clearInputs() {
        this._inputField.value = ''
        this._displayField.value = ''
    }

    private _updateInputs() {
        const {input, display} = this._weekMode ? this._getDateForWeekInput() :
            this._getDateForDayInput()
        this._inputField.value = input
        this._displayField.value = display
    }

    public toggleVisibility(state?: boolean) {
        const _current = this._root.dataset.state === 'true'

        if (this._root && this._root.dataset) {
            this._root.dataset.state = `${typeof state !== 'undefined' ? state :
                !_current}`
        }
        /*
        setTimeout(
            () => {
                if (this._root && this._root.dataset) {
                    this._root.dataset.state = `${typeof state !== 'undefined' ? state : !_current}`
                }
            },
            state ? 250 : 20
        )
         */
    }

    public clearDate(): void {
        this._clearInputs()
        this.toggleVisibility(false)
    }

    private _setDate(date: Date): void {
        this._selectedDate = date
        this.reset()
        this._root.dispatchEvent(new CustomEvent(Events.DATE_UPDATED, {
            detail: {
                id: this.id, date: date
            }
        }))
    }

    public setDate(_date: Date | null | undefined): void {
        const date: Date = _date ?? new Date()
        if (date.getTime() < this._minDate.getTime() || date.getTime() > this._maxDate.getTime()) {
            return
        }
        this._setDate(date)
    }

    public reset() {
        this._month = this._selectedDate.getMonth()
        this._year = this._selectedDate.getFullYear()
        Calendar.API.getHolidaysByYear(this._year)

        this._updateUi()
        this._updateInputs()
    }

    public prevMonth() {
        if (this._isMinMonth()) {
            return
        }

        if (--this._month < 0) {
            this._month += 12
            this._year--
            Calendar.API.getHolidaysByYear(this._year)
        }

        this._updateUi()
    }

    public nextMonth() {
        if (this._isMaxMonth()) {
            return
        }

        if (++this._month >= 12) {
            this._month -= 12
            this._year++
            Calendar.API.getHolidaysByYear(this._year)
        }

        this._updateUi()
    }

    public setTodayDate() {
        const _date = this._weekMode ? this._getWeekBeginDate(new Date()) :
            new Date()
        this.setDate(_date)
    }

    private _isDateToday(date: Date) {
        const _d = new Date()
        return date.getDate() === _d.getDate() && date.getMonth() === _d.getMonth() && date.getFullYear() === _d.getFullYear()
    }

    private _localize() {
        const locale = (navigator.languages && navigator.languages[0]) || navigator.language

        const date = new Date()
        date.setDate(date.getDate() - date.getDay())

        this._dayNames = DayNames.map((defaultDay, i) => {
            const date = new Date()
            date.setDate(date.getDate() - date.getDay() + i)
            return date.toLocaleString(locale, {weekday: 'short'}) || defaultDay
        })

        this._monthNames = MonthNames.map((defaultMonth, i) => {
             return new Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(2023, i, 1))
        });

        this._startOfWeek = this._startOfWeek ? this._startOfWeek : 0
    }

    public static closeOther(except: number) {
        let THIS = null
        Calendar.list.forEach(_calendar => {
            if (except !== _calendar.id) {
                _calendar.toggleVisibility(false)
            } else {
                THIS = _calendar
            }
        })
        Calendar.list = new Map()
        if (THIS) {
            Calendar.list.set(except, THIS)
        }
    }

    private _createUi() {
        this._root.dataset.state = 'false'
        this._container.classList.add(ClassNames.CONTAINER)
        this._root.classList.add(ClassNames.ROOT)
        this._root.appendChild(this._container)

        const body = document.createElement('div')
        body.className = ClassNames.BODY

        const control = document.createElement('div')
        control.className = ClassNames.CONTROL
        this._inputField.className = ClassNames.INPUT_FIELD

        this._todayBtn.className = ClassNames.TODAY_BTN
        this._closeBtn.className = ClassNames.CLOSE_BTN

        this._todayBtn.innerText = this._weekMode ? gt('THIS_WEEK') ?? 'Heute' :
            gt('TODAY') ?? 'Heute'

        this._displayField.addEventListener('focus', debounce(() => {
            if (!this._inputField.value) {
                this.setDate(this._selectedDate)
            }
            this.toggleVisibility(true)
            Calendar.closeOther(this.id)
        }, 20))

        this._inputField.addEventListener('keyup', e => {
            const _isEmpty = this._inputField.value.trim().length === 0
            const _isValid = this._inputField.validity.valid
            const _isEnter = e.key.toLowerCase().includes('enter')
            if (_isEmpty && _isEnter) {
                this.clearDate()
            } else if (_isEnter && _isValid) {
                const weekNumber = this._inputField.value
                let date
                this._weekMode ? date = moment().year(this._year)
                        .week(parseInt(weekNumber)).day("Monday").toDate() :
                    date = moment(this._inputField.value, 'DD-MM-YYYY')
                        .toDate();
                const event = new CustomEvent(Events.DATE_SELECTED, {
                    detail: {
                        id: this.id,
                        date: this._weekMode ? this._getWeekBeginDate(date) :
                            date
                    }
                })
                this._root.dispatchEvent(event)
            }
        })

        this._todayBtn.removeEventListener('click', debounce(() => {
            this.setTodayDate()
        }, 20))

        this._todayBtn.addEventListener('click', debounce(() => {
            this.setTodayDate()
        }, 20))

        this._closeBtn.addEventListener('click', debounce(() => {
            this.toggleVisibility(false)
        }, 20))

        const label = document.createElement('label')
        label.classList.add(ClassNames.LABEL)
        label.appendChild(this._inputField)

        control.appendChild(label)
        control.appendChild(this._todayBtn)
        control.appendChild(this._closeBtn)

        const header = document.createElement('div')
        header.className = ClassNames.HEADER

        this._monthElem.className = ClassNames.MONTH
        header.appendChild(this._monthElem)

        header.appendChild(document.createTextNode(' '))

        this._yearElem.className = ClassNames.YEAR
        header.appendChild(this._yearElem)

        this._prevBtn.classList.add(ClassNames.BTN, ClassNames.BTN_PREV)
        this._prevBtn.removeEventListener('click', debounce(() => {
            this.prevMonth()
        }, 20))
        this._prevBtn.addEventListener('click', debounce(() => {
            this.prevMonth()
        }, 20))
        header.appendChild(this._prevBtn)

        this._nextBtn.classList.add(ClassNames.BTN, ClassNames.BTN_NEXT)
        this._nextBtn.removeEventListener('click', debounce(() => {
            this.nextMonth()
        }, 20))
        this._nextBtn.addEventListener('click', debounce(() => {
            this.nextMonth()
        }, 20))
        header.appendChild(this._nextBtn)

        body.appendChild(header)

        this._container.appendChild(control)
        this._container.appendChild(body)

        const table = document.createElement('table')
        table.className = ClassNames.TABLE

        const tableHead = document.createElement('thead')
        tableHead.className = ClassNames.TABLE_HEAD

        const tableHeadRow = document.createElement('tr')
        tableHeadRow.className = ClassNames.TABLE_ROW

        const weekCol = document.createElement('th')
        weekCol.innerText = `KW`
        weekCol.scope = 'col'
        weekCol.dataset.head = 'weeknumber'
        weekCol.className = ClassNames.TABLE_COL_HEADER
        tableHeadRow.appendChild(weekCol)

        for (let i = 0; i < 7; i++) {
            const day = this._dayNames[(i + this._startOfWeek) % 7]
            const colHeader = document.createElement('th')
            colHeader.scope = 'col'
            colHeader.dataset.head = i === 5 || i === 6 ? `holiday` : `workday`
            colHeader.className = ClassNames.TABLE_COL_HEADER
            colHeader.textContent = day
            tableHeadRow.appendChild(colHeader)
        }

        tableHead.appendChild(tableHeadRow)

        table.appendChild(tableHead)

        this._tableBody.className = ClassNames.TABLE_BODY
        this._tableBody.addEventListener('click', event => {
            const _target = event.target ? (event.target as HTMLElement) :
                this._tableBody
            const _isTooltip = _target.dataset.type === 'tooltip'
            const _tooltipParent = _target.parentElement && _target.parentElement.parentElement ?
                _target.parentElement.parentElement : _target
            const target = _isTooltip ? _tooltipParent : _target
            const _isTableCell = target.classList.contains(
                ClassNames.TABLE_CELL)
            const _targetDate = target.dataset.date && !isNaN(
                parseInt(target.dataset.date)) ? parseInt(target.dataset.date) :
                1

            if (_isTableCell && _targetDate) {
                const _date = new Date(this._year, this._month, _targetDate)
                const event = new CustomEvent(Events.DATE_SELECTED, {
                    detail: {
                        id: this.id,
                        date: this._weekMode ? this._getWeekBeginDate(_date) :
                            _date
                    }
                })
                this._root.dispatchEvent(event)
            }
        })
        table.appendChild(this._tableBody)

        body.appendChild(table)
    }

    private _getWeekBeginDate(date: Date) {
        const _day = 1000 * 60 * 60 * 24
        const _dow = date.getDay() === 0 ? 6 : date.getDay() - 1
        const _current = date.getTime()
        return new Date(_current - _day * _dow)
    }

    private _getWeekNumber(date: Date) {
        return getWeekNumber(date)
    }

    private _addHolidayTooltip(cell: HTMLTableCellElement, info: HolidayInfo) {
        const container = document.createElement('div')
        const tooltip = document.createElement('span')
        const icon = document.createElement('i')
        container.classList.add(ClassNames.TOOLTIP)

        tooltip.dataset.content = info.regions.toLowerCase()
            .includes('national') ? `${info.title}` :
            `${info.title}\n\r${gt('widget.calendar.regions')} ${info.regions}`
        tooltip.dataset.type = 'tooltip'
        icon.className = 'i i-Info small ms-2'
        icon.dataset.toggle = 'tooltip'
        container.appendChild(tooltip)
        tooltip.appendChild(icon)
        cell.appendChild(container)
    }

    private _setSelection(cell: HTMLTableCellElement,
                          row: HTMLTableRowElement) {
        if (this._weekMode) {
            row.dataset.selected = 'true'
        } else {
            cell.dataset.selected = 'true'
        }
    }

    private _updateUi() {
        this._monthElem.textContent = this._monthNames[this._month]

        this._yearElem.textContent = `${this._year}`

        if (this._isMinMonth()) {
            this._prevBtn.classList.add(ClassNames.BTN_DISABLED)
        } else {
            this._prevBtn.classList.remove(ClassNames.BTN_DISABLED)
        }

        if (this._isMaxMonth()) {
            this._nextBtn.classList.add(ClassNames.BTN_DISABLED)
        } else {
            this._nextBtn.classList.remove(ClassNames.BTN_DISABLED)
        }

        this._tableBody.innerHTML = ''

        const date = new Date(this._year, this._month, 1)

        date.setDate(1 - ((date.getDay() + 7 - this._startOfWeek) % 7))

        do {
            const row = document.createElement('tr')
            const weekNumber = document.createElement('td')
            weekNumber.classList.add(ClassNames.TABLE_CELL)
            weekNumber.dataset.type = 'weeknumber'
            weekNumber.innerText = `${this._getWeekNumber(date)}`
            row.appendChild(weekNumber)

            for (let i = 0; i < 7; i++) {
                const cell = document.createElement('td')
                cell.textContent = `${date.getDate()}`
                cell.classList.add(ClassNames.TABLE_CELL)
                cell.dataset.type = date.getDay() === 0 || date.getDay() === 6 ?
                    `holiday` : `workday`

                if (date.getMonth() == this._month) {
                    if (this._isDateToday(date)) {
                        cell.dataset.today = 'true'
                    }

                    const {isHoliday, isNational} = Calendar.API.checkDate(date)
                    cell.dataset.date = `${date.getDate()}`

                    if (isHoliday) {
                        cell.dataset.holiday = isHoliday && isNational ?
                            `national` : `local`

                        this._addHolidayTooltip(cell,
                            Calendar.API.getInfo(date))
                    }

                    if (date.getTime() == new Date(
                        this._selectedDate.getFullYear(),
                        this._selectedDate.getMonth(),
                        this._selectedDate.getDate()).getTime()) {
                        this._setSelection(cell, row)
                    }

                    if (this._today.getTime() > date.getTime()) {
                        cell.dataset.past = `true`
                    }

                    if (!weekNumber.dataset.date) {
                        weekNumber.dataset.date = `${date.getDate()}`
                    }
                } else if (date.getMonth() !== this._month) {
                    cell.dataset.disabled = 'true'

                    if (this._today.getTime() > date.getTime()) {
                        cell.dataset.past = `true`
                    }
                }

                row.appendChild(cell)

                date.setDate(date.getDate() + 1)
            }

            this._tableBody.appendChild(row)
        } while (date.getMonth() == this._month)

        for (let x = this._tableBody.children.length; x < 6; x++) {
            const row = document.createElement('tr')
            for (let y = 0; y < 8; y++) {
                const td = document.createElement('td')
                td.dataset.lastRow = 'true'
                td.classList.add(ClassNames.TABLE_CELL)
                row.appendChild(td)
            }
            this._tableBody.appendChild(row)
        }

        Tooltip.initElements()
    }

    private _isMinMonth() {
        return this._month == this._minDate.getMonth() && this._year == this._minDate.getFullYear()
    }

    private _isMaxMonth() {
        return this._month == this._maxDate.getMonth() && this._year == this._maxDate.getFullYear()
    }
}

const _generateWeekContent = (num: number, _id: string | null,
                              current: number) => {
    const id = _id ? `${_id}-checkbox` : ''
    const label = document.createElement('label')
    const span = document.createElement('span')
    const checkbox = document.createElement('input')
    if (id.length) {
        checkbox.setAttribute('data-id', `${id}`)
    }

    checkbox.setAttribute('data-value', `${num}`)
    checkbox.type = 'checkbox'
    span.innerText = `${num}`
    label.appendChild(checkbox)
    label.appendChild(span)
    if (num === current) {
        label.dataset.current = 'true'
    }
    return label
}

const _initWeekNumbers = (parent: HTMLElement) => {
    if (!parent || parent.dataset.init) return

    const _customId = parent.getAttribute('id')
    const today = new Date()
    const _date = new Date(today.getFullYear(), 11, 31)
    const _total = getWeekNumber(_date)
    const _currentWeek = getWeekNumber(today)

    for (let x = 1; x < _total; x++) {
        parent.appendChild(_generateWeekContent(x, _customId, _currentWeek))
    }
    parent.dataset.init = 'true'
}

export const initWeekCalendar = (selector: string): void => {
    const calendarElem = document.querySelectorAll(selector)
    if (!calendarElem.length) return

    calendarElem.forEach(calendar => _initWeekNumbers(calendar as HTMLElement))
}

export const initCalendar = (selector: string): void => {
    const observer = new IntersectionObserver(entries => {
        setTimeout(() => {
            entries.forEach(entry => {
                setTimeout(() => {
                    const sideThreshold = (window.innerWidth / 2) * 1.25
                    const side = entry.boundingClientRect.left > sideThreshold
                    const direction = entry.boundingClientRect.top > window.innerHeight / 2 ?
                        'top' : 'bottom'
                    entry.target.setAttribute('data-direction', `${direction}`)
                    side && entry.target.setAttribute('data-side', 'right')
                }, 250)
            })
        }, 450)
    }, {
        root: null
    })

    const calendarElem = document.querySelectorAll(selector)
    if (!calendarElem.length) return

    const _needToInit = [...calendarElem]
        .filter(
            _calendar => !_calendar.querySelector(`.${ClassNames.CONTAINER}`))
        .map(item => item as HTMLElement)
    if (!_needToInit.length) return

    const today = new Date()

    _needToInit.forEach(_calendar => {
        const calendar = new Calendar(_calendar, {
            today: new Date(today.getFullYear(), today.getMonth(),
                today.getDate()),
            selectedDate: new Date(today.getFullYear(), today.getMonth(),
                today.getDate())
        })

        observer.observe(_calendar)

        _calendar.removeEventListener(Events.DATE_SELECTED,
            ((event: CustomEvent) => {
                const _date = event.detail.date as Date
                if (_date) {
                    calendar.setDate(_date)
                }
                calendar.toggleVisibility(false)
            }) as EventListener)

        _calendar.addEventListener(Events.DATE_SELECTED,
            ((event: CustomEvent) => {
                const _date = event.detail.date as Date
                if (_date) {
                    calendar.setDate(_date)
                }
                calendar.toggleVisibility(false)
            }) as EventListener)

        _calendar.removeEventListener(Events.SET_TODAY, () => {
            calendar.setTodayDate()
        })

        _calendar.addEventListener(Events.SET_TODAY, () => {
            calendar.setTodayDate()
        })
    })
}
